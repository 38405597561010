import React, { Component } from "react"

class StoreConstructionLensRow extends Component {

  render() {
    const {
      product,
      onPlusMinusClick,
      onInputChange,
      cartUploading,
      userIsClient,
    } = this.props

    return (
      <tr>
        <td className={"products-table-description-cell"}>
          {product.attributes.description}
        </td>

        <td className="c products-table-quantity">
          <div className={"quantity-spinner"}>
            <button
              disabled={cartUploading}
              className="btn btn-secondary products-table-quantity-remove"
              onClick={e => {
                e.preventDefault()
                onPlusMinusClick(product, "quantity", -1)
              }}
            >
              -
            </button>

            <input
              id="quantity"
              placeholder="0"
              className="form-control products-table-quantity-input"
              type="number"
              value={product.quantity}
              onChange={e => {
                e.preventDefault()
                const { currentTarget } = e
                onInputChange(product, currentTarget.id, currentTarget.value)
              }}
            />

            <button
              disabled={cartUploading}
              className="btn btn-secondary products-table-quantity-add"
              onClick={e => {
                e.preventDefault()
                onPlusMinusClick(product, "quantity", 1)
              }}
            >
              +
            </button>
          </div>
        </td>
        {!userIsClient && (
          <td className="c products-table-quantity"></td>
        )}
      </tr>
    )
  }
}

export default StoreConstructionLensRow
