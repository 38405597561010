import React, { Component } from "react"

class storeRow extends Component {
  render() {
    const {
      product,
      onPlusMinusClick,
      onInputChange,
      cartUploading,
      userIsClient,
    } = this.props

    return (
      <tr>
        <td className={"products-table-description-cell"}>
          {product.attributes.description}
        </td>

        <td className="c products-table-quantity">
          <div className={"quantity-spinner"}>
            <button
              disabled={cartUploading}
              className="btn btn-secondary products-table-quantity-remove"
              onClick={e => {
                e.preventDefault()
                onPlusMinusClick(product, "quantity", -1)
              }}
            >
              -
            </button>

            <input
              id="quantity"
              placeholder="0"
              className="form-control products-table-quantity-input"
              type="number"
              value={product.quantity}
              onChange={e => {
                e.preventDefault()
                const { currentTarget } = e
                onInputChange(product, currentTarget.id, currentTarget.value)
              }}
            />

            <button
              disabled={cartUploading}
              className="btn btn-secondary products-table-quantity-add"
              onClick={e => {
                e.preventDefault()
                onPlusMinusClick(product, "quantity", 1)
              }}
            >
              +
            </button>
          </div>
        </td>
        {!userIsClient && (
          <td className="c products-table-quantity">
            {!product.meta.is_free_sample && (
              <div className={"quantity-spinner"}>
                <button
                  disabled={cartUploading}
                  className="btn btn-secondary products-table-quantity-remove"
                  onClick={e => {
                    e.preventDefault()
                    onPlusMinusClick(product, "discountedQuantity", -1)
                  }}
                >
                  -
                </button>

                <input
                  id="discountedQuantity"
                  placeholder="0"
                  disabled={cartUploading}
                  className="form-control products-table-quantity-input"
                  type="number"
                  value={product.discountedQuantity}
                  onChange={e => {
                    e.preventDefault()
                    const { currentTarget } = e
                    onInputChange(product, currentTarget.id, currentTarget.value)
                  }}
                />

                <button
                  disabled={cartUploading}
                  className="btn btn-secondary products-table-quantity-add"
                  onClick={e => {
                    e.preventDefault()
                    onPlusMinusClick(product, "discountedQuantity", 1)
                  }}
                >
                  +
                </button>
              </div>
            )}
          </td>
        )}
      </tr>
    )
  }
}

export default storeRow
