import React, { Component } from "react"
import { v4 as uuidv4 } from 'uuid';

class ConstructionLensFields extends Component {

  state = {
    sphere: '+0.25',
    cylinder: '-0.25',
    axis: '1',
    radius: '7.00',
    diameter: '11.00',
  }

  componentDidMount = () => {
    this.handleChange()
  }

  roundValue = (value, step, decimal = 2) => {
    const carry = value % step

    if (carry > 0) {
      const lowerStep = value - carry
      const upperStep = value - carry + step

      value = upperStep - value < value - lowerStep ? upperStep : lowerStep
    } else if (carry < 0) {
      value = Number(value)
      const lowerStep = value - carry - step
      const upperStep = value - carry

      value = upperStep - value < value - lowerStep ? upperStep : lowerStep
    }

    return Number(value).toFixed(decimal)
  }

  handleSphereChange = (e) => {
    e.preventDefault()
    const step = 0.25
    let value = e.target.value
    const state = this.state

    if (Number.isNaN(value) || value === '') {
      return
    }

    value = this.roundValue(value, step)

    state['sphere'] = value > 0 ? `+${value}` : value

    this.setState(state)
    this.handleChange()
  }

  handleCylinderChange = (e) => {
    e.preventDefault();
    const step = 0.25
    let value = e.target.value
    const state = this.state

    if (Number.isNaN(value) || value === '') {
      return
    }

    // Convert to negative number
    value = value > 0 ? value * -1 : value

    value = this.roundValue(value, step)

    state['cylinder'] = value > 0 ? `-${value}` : value

    this.setState(state)
    this.handleChange()
  }

  handleAxisChange = (e) => {
    e.preventDefault();
    const step = 1
    const max = 180
    const min = 1
    let value = e.target.value
    const state = this.state

    if (Number.isNaN(value) || value === '') {
      return
    }

    // Convert to positive number
    value = value < 0 ? value * -1 : value

    value = this.roundValue(value, step, 0)

    if (value > max) {
      value = max.toFixed(0)
    } else if (value >= 0 && value < min) {
      value = min.toFixed(0)
    }

    state['axis'] = value

    this.setState(state)
    this.handleChange()
  }

  handleRadiusChange = (e) => {
    e.preventDefault();
    const step = 0.05
    const max = 9.9
    const min = 7
    let value = e.target.value
    const state = this.state

    if (Number.isNaN(value) || value === '') {
      return
    }

    // Convert to positive number
    value = value < 0 ? value * -1 : value

    value = this.roundValue(value, step)

    if (value > max) {
      value = max.toFixed(2)
    } else if (value >= 0 && value < min) {
      value = min.toFixed(2)
    }

    state['radius'] = value

    this.setState(state)
    this.handleChange()
  }

  handleDiameterChange = (e) => {
    e.preventDefault();
    const step = 0.1
    const max = 17
    const min = 11
    let value = e.target.value
    const state = this.state

    if (Number.isNaN(value) || value === '') {
      return
    }

    // Convert to positive number
    value = value < 0 ? value * -1 : value

    value = this.roundValue(value, step)

    if (value > max) {
      value = max.toFixed(2)
    } else if (value >= 0 && value < min) {
      value = min.toFixed(2)
    }

    state['diameter'] = value

    this.setState(state)
    this.handleChange()
  }

  handleChange = () => {
    const { onChange } = this.props
    const {
      sphere,
      cylinder,
      axis,
      radius,
      diameter
    } = this.state

    onChange({
      sphere,
      cylinder,
      axis,
      radius,
      diameter
    })
  }

  render() {
    const {
      sphere,
      cylinder,
      axis,
      radius,
      diameter
    } = this.state

    return (
      <div className="construction-lens-fields-container">

        <div className="mt-3">
          <div className="row">

            <div className="col-12 col-sm-6">
              <label htmlFor="construction-lens-sphere-control" className="ml-2 mt-2 mb-0 form-label">Sfera</label>
              <input
                id="construction-lens-sphere-control"
                className="form-control"
                type="number"
                step="0.25"
                onChange={e => this.handleSphereChange(e)}
              />
              <div className="mb-2"><small>Valori accettati: multipli di 0.25 con segno + o -</small></div>
              <p className="mb-2 construction-lens-normalized-value">Valore normalizzato: <strong>{sphere}</strong></p>
            </div>

            <div className="col-12 col-sm-6">
              <label htmlFor="construction-lens-cylinder-control" className="ml-2 mt-2 mb-0 form-label">Cilindro</label>
              <input
                id="construction-lens-cylinder-control"
                className="form-control"
                type="number"
                step="0.25"
                max="-0.25"
                onChange={e => this.handleCylinderChange(e)}
              />
              <div className="mb-2"><small>Valori accettati: multipli di 0.25 con segno -</small></div>
              <p className="mb-2 construction-lens-normalized-value">Valore normalizzato: <strong>{cylinder}</strong></p>
            </div>

          </div>
        </div>

        <div className="mt-3">
          <div className="row">

            <div className="col-12 col-sm-6">
              <label htmlFor="construction-lens-axis-control" className="ml-2 mt-2 mb-0 form-label">Asse</label>
              <input
                id="construction-lens-axis-control"
                className="form-control"
                type="number"
                step="1"
                min="1"
                max="180"
                onChange={e => this.handleAxisChange(e)}
              />
              <div className="mb-2"><small>Valori accettati: compresi tra 1 e 180</small></div>
              <p className="mb-2 construction-lens-normalized-value">Valore normalizzato: <strong>{axis}</strong></p>
            </div>

            <div className="col-12 col-sm-6">
            <label htmlFor="construction-lens-radius-control" className="ml-2 mt-2 mb-0 form-label">Raggio</label>
              <input
                id="construction-lens-radius-control"
                className="form-control"
                type="number"
                step="0.05"
                min="7"
                max="9.90"
                onChange={e => this.handleRadiusChange(e)}
              />
              <div className="mb-2"><small>Valori accettati: compresi tra 7.00 e 9.90 multipli di 0.05</small></div>
              <p className="mb-2 construction-lens-normalized-value">Valore normalizzato: <strong>{radius}</strong></p>
            </div>
            
          </div>
        </div>

        <div className="mt-3">
          <div className="row">
            <div className="col-12 col-sm-6">
              <label htmlFor="construction-lens-diameter-control" className="ml-2 mt-2 mb-0 form-label">Diametro</label>
              <input
                id="construction-lens-diameter-control"
                className="form-control"
                type="number"
                step="0.1"
                min="11"
                max="17"
                onChange={e => this.handleDiameterChange(e)}
              />
              <div className="mb-2"><small>Valori accettati: compresi tra 11.00 e 17.00 multipli di 0.10</small></div>
              <p className="mb-2 construction-lens-normalized-value">Valore normalizzato: <strong>{diameter}</strong></p>
            </div>
            <div className="col-12 col-sm-6">

            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default ConstructionLensFields
